import React, { useContext } from 'react';
import { StaticContext } from '@sm/webassets/StaticContext';

import { IconLock } from '@wds/icons';
import { Tooltip } from '@wds/tooltip';
import { useSurveyTheme } from '@sm/webassets/SurveyTheme/context';

import { useAppSelectorV2 as useAppSelector } from '~app/hooks';
import { getThemeLayoutType } from '~app/helpers/themeLayout';
import getLinkBasedOnLangCode from '~app/helpers/languageLinks';
import { selectIsWhiteLabel } from '~app/pages/SurveyTaking/v2/slices/surveySlice';
import { DEFAULT_DOMAIN } from '~helpers/buildCanonicalUrl';

import { SurveyLink } from '../SurveyLink';
import useStyles from './useStyles';

type FooterPrivacyIconProps = {
  /**
   * Show the privacy icon just above PersistentProgressBar when mode is OQAAT and Progress bar is enabled
   * Otherwise, show the privacy icon at the bottom of the page
   */
  needsBottomOffset: boolean;
};

const FooterPrivacyIcon: React.FC<FooterPrivacyIconProps> = ({ needsBottomOffset }) => {
  const isWhiteLabel = useAppSelector(selectIsWhiteLabel);
  const { layout: layoutName } = useSurveyTheme();
  const { isPartialRight } = getThemeLayoutType(layoutName);

  const { footerPrivacyContainer } = useStyles({ needsBottomOffset });

  const {
    environment: { domain, languageCode },
  } = useContext(StaticContext);

  const tooltipPlacement = isPartialRight ? 'right' : 'left';

  const PRIVACY_BASICS_URL = getLinkBasedOnLangCode({
    languageCode,
    domain: isWhiteLabel ? DEFAULT_DOMAIN : domain,
    path: `/mp/legal/privacy/?ut_source=survey_pp${isWhiteLabel ? '&white_label=1' : ''}`,
  });

  return (
    <div className={footerPrivacyContainer} id="privacy-icon-lock" data-testid="FooterPrivacyIcon__PrivacyIcon">
      <Tooltip text="Privacy & Cookie Notice" placement={tooltipPlacement}>
        <SurveyLink rel="noreferrer" href={PRIVACY_BASICS_URL} target="_blank" aria-label="SurveyMonkey Privacy Icon">
          <IconLock color="light" size="md" title="Privacy & Cookie Notice" />
        </SurveyLink>
      </Tooltip>
    </div>
  );
};

export default FooterPrivacyIcon;
