import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

type StyleNames = 'pageNavigationContainer' | 'navButtons';
type StyleProps = { isRTL: boolean };

const useStyles = createSurveyStyles<StyleNames, StyleProps>(theme => {
  return {
    // Page Navigation
    pageNavigationContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'stretch',
      flexWrap: 'wrap',

      // negative margin to counter right-side margin on buttons (so they align with the right edge)
      marginRight: '-5px',
      [`@media (max-width: ${theme.breakpoints?.xs.max ?? '640px'})`]: {
        marginRight: '-10px',
      },

      [`@media (max-width: ${theme.breakpoints?.xxs.max ?? '480px'})`]: {
        flexWrap: 'nowrap',
      },
    },

    // Page Navigation Buttons
    navButtons: {
      marginBottom: '10px',
      marginRight: '5px',
      transition: 'all 0.2s linear',

      [`@media (max-width: ${theme.breakpoints?.xs.max ?? '640px'})`]: {
        marginRight: '10px',
      },

      [`@media (max-width: ${theme.breakpoints?.xxs.max ?? '480px'})`]: {
        flexBasis: '100%',
      },
    },
  };
});

export default useStyles;
