// New type created based on Smquestions ResponseValue type
// May need to be updated if Smquestions updates their ResponseValue type
// apps/respweb/node_modules/@sm/question-definitions/@types/questionType/respondent.types.d.ts
type AnswerValue = {
  id: string;
  value:
    | string
    | AnswerValue[]
    | {
        date?: string;
        hour?: string;
        minute?: string;
        period?: 'AM' | 'PM';
      };
};

const containsMatchingObject = (currentAnswer: AnswerValue[], updatedAnswerObj: AnswerValue): boolean => {
  let sortedUpdatedValue = updatedAnswerObj.value;
  if (Array.isArray(updatedAnswerObj.value)) {
    sortedUpdatedValue = updatedAnswerObj.value.slice().sort((a, b) => Number(a.id) - Number(b.id));
  }
  return currentAnswer.some(item => {
    let sortedItemValue = item.value;
    if (Array.isArray(item.value)) {
      sortedItemValue = item.value.slice().sort((a: AnswerValue, b: AnswerValue) => Number(a.id) - Number(b.id));
    }

    return item.id === updatedAnswerObj.id && JSON.stringify(sortedItemValue) === JSON.stringify(sortedUpdatedValue);
  });
};

/**
 * Check if the updated answer matches the current answer.
 * @param updatedAnswer - The updated answer to compare.
 * @param currentAnswer - The current answer to compare.
 * @returns True if the answers match, false otherwise.
 */
const answersMatch = (updatedAnswer: AnswerValue[], currentAnswer: AnswerValue[]): boolean => {
  if (updatedAnswer.length !== currentAnswer.length) {
    return false;
  }

  return updatedAnswer.every(updatedObj => containsMatchingObject(currentAnswer, updatedObj));
};

export default answersMatch;
