import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useSurveyTheme } from '@sm/webassets/SurveyTheme/context';
import { L10NContext } from '@sm/intl';
import { getThemeLayoutType } from '~app/helpers/themeLayout';
import useStyles from './useStyles';
import FooterPrivacyIcon from '~app/components/Survey/FooterPrivacyIcon';

export type LayoutContainerName =
  /** Outer/Wrapping container */
  | 'layoutContainer'
  /**  Left/Right/Top Spacing/Image container */
  | 'partialContainer'
  /** Outer Content container */
  | 'contentContainer'
  /**  */
  | 'topContainer'
  /** Inner Content Container */
  | 'mainContainer'
  /** Logo container (moves between Partial <=> Content) */
  | 'logoContainer'
  /** Toolbar container (Exit & Locale, etc) */
  | 'toolbarContainer'
  /** Privacy Icon container when no footer is enabled */
  | 'footerPrivacyIconContainer';

/** Allows a string (ID) or any HTMLDivElement props with React.Ref */
export type ContainerProps = React.HTMLAttributes<HTMLDivElement> & { ref?: React.RefObject<HTMLDivElement> };

export type LayoutContainerOptions = Record<LayoutContainerName, ContainerProps>;

export type Props = React.PropsWithChildren<{
  /** An element to show in the logo container */
  logo?: React.ReactElement | false;
  /** Element(s) to show in the toolbar container */
  tools?: React.ReactElement | false;
  /** Container Options (props) */
  options: Partial<LayoutContainerOptions>;
  showFooterPrivacyIcon?: boolean;
  isOQAAT?: boolean;
  isProgressBarEnabled?: boolean;
}>;

const defaultContainerProps: LayoutContainerOptions = {
  layoutContainer: { id: 'survey-layout-container' },
  partialContainer: { id: 'survey-layout-partial-container' },
  contentContainer: { id: 'survey-layout-content-container' },
  topContainer: { id: 'survey-layout-top-container' },
  mainContainer: { id: 'survey-main-content' },
  logoContainer: { id: 'survey-logo-container' },
  toolbarContainer: { id: 'survey-toolbar-container' },
  footerPrivacyIconContainer: { id: 'privacy-icon-lock' },
};

function Layout({
  options: containerOptions = {},
  logo: Logo,
  tools: Tools,
  showFooterPrivacyIcon,
  isOQAAT,
  isProgressBarEnabled,
  children,
}: Props): React.ReactElement {
  const { layout: layoutName } = useSurveyTheme();
  const { isBanner } = getThemeLayoutType(layoutName);
  const { isRTL } = useContext(L10NContext);

  const isToolbarEnabled = !!Tools;
  const isLogoEnabled = !!Logo;

  const {
    layoutContainer,
    layoutLogoContainer,
    layoutPartialContainer,
    layoutToolbarContainer,
    layoutContentContainer,
    layoutTopContainer,
    layoutMainContainer,
    footerPrivacyIconContainer,
  } = useStyles({ isRTL });

  const {
    layoutContainer: { className: layoutContainerClasses, ...layoutContainerProps },
    partialContainer: { className: partialContainerClasses, ...partialContainerProps },
    contentContainer: { className: contentContainerClasses, ...contentContainerProps },
    topContainer: { className: topContainerClasses, ...topContainerProps },
    mainContainer: { className: mainContainerClasses, ...mainContainerProps },
    toolbarContainer: { className: toolbarContainerClasses, ...toolbarContainerProps },
    logoContainer: { className: logoContainerClasses, ...logoContainerProps },
  } = useMemo(() => {
    return Object.keys(containerOptions).reduce((result: LayoutContainerOptions, key: string) => {
      return { ...result, [key]: containerOptions[key as LayoutContainerName] ?? {} };
    }, defaultContainerProps);
  }, [containerOptions]);

  return (
    <div className={classNames(layoutContainer, layoutContainerClasses)} {...layoutContainerProps}>
      <div className={classNames(layoutPartialContainer, partialContainerClasses)} {...partialContainerProps}>
        {isBanner && isLogoEnabled && (
          <div className={classNames(layoutLogoContainer, logoContainerClasses)} {...logoContainerProps}>
            {Logo}
          </div>
        )}
      </div>
      <div className={classNames(layoutContentContainer, contentContainerClasses)} {...contentContainerProps}>
        <div className={classNames(layoutTopContainer, topContainerClasses)} {...topContainerProps}>
          {isToolbarEnabled && (
            <div className={classNames(layoutToolbarContainer, toolbarContainerClasses)} {...toolbarContainerProps}>
              {Tools}
            </div>
          )}
          {!isBanner && isLogoEnabled && (
            <div className={classNames(layoutLogoContainer, logoContainerClasses)} {...logoContainerProps}>
              {Logo}
            </div>
          )}
        </div>
        <div
          className={classNames(layoutMainContainer, mainContainerClasses)}
          suppressHydrationWarning // styles can be set by js in pages/_document.page.tsx
          {...mainContainerProps}
        >
          {children}
        </div>
        {showFooterPrivacyIcon && (
          <div className={footerPrivacyIconContainer}>
            <FooterPrivacyIcon needsBottomOffset={!!(isOQAAT && isProgressBarEnabled)} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Layout;
