import { createUseStyles } from 'react-jss';

type StyleClasses = 'footerPrivacyContainer';
type StyleProps = {
  needsBottomOffset: boolean;
};

const useStyles = createUseStyles<StyleClasses, StyleProps>({
  footerPrivacyContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    textAlign: 'center',
    padding: 5,
    backgroundColor: '#6B787F',
    position: 'relative',
    bottom: ({ needsBottomOffset }) => (needsBottomOffset ? 48 : 15),

    [`@media (max-width: 768px)`]: {
      '& span[role="tooltip"]': {
        display: 'none',
      },
    },
  },
});

export default useStyles;
