import React, { useState } from 'react';

import { defineMessages, t } from '@sm/intl';
import { CaretDownOutlineIcon } from '@sm/question-ui/respondent-survey';
import { LanguageTag } from '~lib/generatedGqlTypes';
import { NativeSelect as Select, NativeSelectOption as Option } from '~app/components/core/NativeSelect';

import useStyles from './useStyles';

const COPY = defineMessages({
  SELECT_LABEL: {
    id: 'SurveyTaking.LanguageSelect.Label',
    defaultMessage: 'Change survey language',
    description: `[Type: label][Vis: high] - the text that describes the language select`,
  },
});

export type LanguageSelectProps = {
  /** The current selected language option (use `LanguageTag.code`) */
  selectedLangCode: string;
  /** The languages representing options within the select element */
  languages: Pick<LanguageTag, 'id' | 'name' | 'displayName' | 'code'>[];
  /** The change event fired when the select value is changed */
  onChange?: React.SelectHTMLAttributes<HTMLSelectElement>['onChange'];
  /** The click event fired when the select element is clicked */
  onClick?: React.SelectHTMLAttributes<HTMLSelectElement>['onClick'];
};

export function LanguageSelect({
  selectedLangCode,
  languages,
  onChange,
  onClick,
}: LanguageSelectProps): React.ReactElement | null {
  const { selectContainer } = useStyles();
  // on initial load currentLang is the default(en) or based on the url of the page if language is already set
  const [currentLang, setCurrentLang] = useState(selectedLangCode);

  // if there is just one language then the survey only supports
  // the currently selected language and we don't show the select
  if (languages.length <= 1) {
    return null;
  }

  const handleLanguageSelect = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    onChange?.(event);
    if (!event.isDefaultPrevented()) {
      setCurrentLang(event.target.value);
      window.location.assign(`?lang=${event.target.value}`);
    }
  };

  return (
    <>
      <Select
        id="survey-language"
        icon={<CaretDownOutlineIcon />}
        onClick={onClick}
        onChange={handleLanguageSelect}
        value={currentLang}
        className={selectContainer}
        data-testid="LanguageSelect"
        aria-label={t(COPY.SELECT_LABEL)}
      >
        {languages.map(lang => (
          <Option key={lang.id} id={lang.id} lang={lang.code.split('_')[0]} value={lang.code}>
            {lang.displayName}
          </Option>
        ))}
      </Select>
    </>
  );
}

export default LanguageSelect;
