type CanonicalUrlParams = {
  domain?: string | undefined;
  subdomain?: string | undefined;
  tld?: string | undefined;
  hostname?: string | undefined;
};

export const DEFAULT_DOMAIN = 'surveymonkey';
export const DEFAULT_HOST = `www.${DEFAULT_DOMAIN}.com`;

// This canonical path is what SEO has said to use for /r/ and endpages
// https://surveymonkey.atlassian.net/browse/WEBPLAT-4439
export const CANONICAL_PATH = '/mp/take-a-tour/';

export function buildCanonicalHostname({ hostname, domain, subdomain, tld }: CanonicalUrlParams): string {
  // return the canonical URL using hostname if it is provided
  if (hostname) {
    return `https://${hostname}`;
  }

  // return the canonical URL using subdomain, domain, and tld
  if (domain && subdomain && tld) {
    return `https://${subdomain}.${domain}.${tld}`;
  }

  // return default canonical URL if any of the required params are missing
  return `https://${DEFAULT_HOST}`;
}

// helper function to build the canonical URL for /r/ and endpages
export function buildCanonicalUrl({ hostname, domain, subdomain, tld }: CanonicalUrlParams): string {
  return `${buildCanonicalHostname({ hostname, domain, subdomain, tld })}${CANONICAL_PATH}`;
}
